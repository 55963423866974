<template>
  <div>
    <KTCodePreview v-bind:title="'Thêm mới nhân sự'">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation>
          <!-- Input session -->
          <div class="row">
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label> Chức vụ: </label>
                    <b-form-select
                      tabindex="7"
                      v-model="position.selected"
                      :options="position.options"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group v-if="position.selected === 3">
                    <template>
                      <label
                        >Khu vực:
                        <span class="text-danger">*</span>
                      </label>
                    </template>
                    <b-form-select
                      size="sm"
                      v-model="selectedArea"
                      :options="listArea"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Khu vực --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng chọn khu vực</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else-if="position.selected === 2">
                    <template>
                      <label
                        >Vùng:
                        <span class="text-danger">*</span>
                      </label>
                    </template>
                    <b-form-select
                      size="sm"
                      v-model="selectedRegion"
                      :options="listRegion"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Vùng --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback
                      >Vui lòng chọn khu vực</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else class="required-control">
                    <label> Cửa hàng: </label>
                    <vue-autosuggest
                      class="border-radius-none"
                      :suggestions="filteredOptions"
                      @selected="onSelectedStore"
                      :limit="100"
                      @input="onInputChange"
                      :input-props="{
                        id: 'autosuggest__input',
                        placeholder: 'Chọn cửa hàng',
                        style: 'border-radius:0px!important',
                      }"
                      :should-render-suggestions="
                        (size, loading) => size >= 0 && !loading
                      "
                      v-model="searchStore"
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        {{ suggestion.item.name }}
                      </div>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Nhân viên phụ trách:</label>
                    <b-form-input
                      tabindex="9"
                      size="sm"
                      placeholder="Nhập nhân viên"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label> SĐT: </label>
                    <b-form-input
                      tabindex="3"
                      style="width: -webkit-fill-available"
                      type="text"
                      size="sm"
                      v-model="mobile"
                      placeholder="Nhập số điện thoại"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Row 2 -->
          <div class="row">
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group class="required-control">
                    <label> Tên nhân sự: </label>
                    <b-form-input
                      tabindex="2"
                      size="sm"
                      v-model="$v.fullName.$model"
                      :state="validateState('fullName')"
                      aria-describedby="input-fullName-live-feedback"
                      placeholder="Nhập tên nhân sự"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.fullName.minLength"
                      id="input-fullName-live-feedback"
                    >
                      Nhập tên nhân sự với ít nhất 3 kí tự!
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.fullName.required"
                      id="input-fullName-live-feedback"
                      >Vui lòng nhập tên nhân sự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label>Mã nhân viên:</label>
                    <b-form-input
                      size="sm"
                      placeholder="Nhập mã nhân viên"
                      v-model="code"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <label for="input-small-name">Ngày sinh:</label>
                  <b-input-group class="mb-3">
                    <date-picker
                      placeholder="Chọn ngày sinh"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="birthDay"
                    ></date-picker>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label> Email: </label>
                    <b-form-input
                      tabindex="6"
                      size="sm"
                      v-model="email"
                      placeholder="Nhập email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Row 3 -->
          <div class="row">
            <b-col cols="3">
              <b-form-group label="Danh sách cửa hàng">
                <b-input-group>
                  <vue-multi-select
                    style="margin-bottom: 2rem"
                    v-model="listStoreId"
                    :options="listStores"
                    placeholder="Chọn cửa hàng"
                    option-label="name"
                    @input="onChangeListStore()"
                  ></vue-multi-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <div class="col-md-3">
              <b-row class="my-1">
                <b-col>
                  <b-form-group>
                    <label for="input-small-part">Bộ phận:</label>
                    <b-form-select
                      size="sm"
                      v-model="selectedDepartment"
                      :options="listDepartment"
                      value-field="id"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn bộ phận --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- End input session -->
        </b-form>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-button
          tabindex="11"
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="createEmployee"
          >Lưu</b-button
        >
        <!-- </b-col> -->
        <!-- <b-col lg="2" class="pb-2"> -->
        <router-link to="/employees" tag="button">
          <b-button
            tabindex="12"
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
        <!-- </b-col> -->
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
.bootstrap-datetimepicker-widget .dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.multi-select-wrapper {
  flex: 1 1 auto;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import VueMultiSelect from 'vue-simple-multi-select';
import { JOB_TITLE } from '../../../utils/constants';
import { makeToastFaile, makeToastSuccess } from '../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      formatted: '',
      selected: '',
      selectedStore: null,
      position: {
        selected: null,
        options: JOB_TITLE,
      },
      active: {
        selected: null,
        options: [
          { value: null, text: 'Hãy chọn trạng thái' },
          { value: '1', text: 'Hoạt động' },
          { value: '0', text: 'Không hoạt động' },
        ],
      },
      id: '',
      fullName: '',
      mobile: '',
      birthDay: '',
      email: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      listRegion: [],
      listArea: [],
      selectedArea: null,
      selectedRegion: null,
      code: '',
      listStoreId: [],
      listStores: [],
      listDepartment: [],
      selectedDepartment: null,
    };
  },
  components: {
    KTCodePreview,
    datePicker,
    VueMultiSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhân sự', route: '/employees' },
      { title: 'Danh sách nhân sự', route: '/employees' },
      { title: 'Thêm mới nhân sự' },
    ]);
    this.fetchStore();
    this.fetchArea();
    this.fetchRegion();
    this.fetchDeparment();
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStores = stores;
      });
    },
    fetchDeparment: async function () {
      this.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.listDepartment = response.data.data;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    createEmployee: async function () {
      this.$v.$touch();

      let empStoreIds = '-1';
      if (this.listStoreId.length > 0) {
        empStoreIds = '';
        this.listStoreId.forEach((element, index) => {
          if (index === this.listStoreId.length - 1) {
            empStoreIds += element.id;
          } else {
            empStoreIds += element.id + ', ';
          }
        });
      }
      const data = {
        fullName: this.$v.fullName.$model,
        code: this.code,
        birthDay: this.birthDay
          ? moment(this.birthDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        jobTitleId: this.position.selected,
        email: this.email,
        store: this.selectedStore,
        active: 1,
        mobile: this.mobile,
        areaId: this.selectedArea,
        regionId: this.selectedRegion,
        listStoreId: empStoreIds,
        departmentId: this.selectedDepartment,
      };

      ApiService.post('employees', data).then((response) => {
        const { status, message } = response.data;
        if (status === 1) {
          makeToastSuccess(message);
          this.$router.push({
            name: 'list-employee',
          });
        } else {
          makeToastFaile(message);
        }
      });
    },
    showSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Thêm nhân viên thành công',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
    },
    fetchArea: async function () {
      this.listArea = [];
      ApiService.setHeader();
      ApiService.get('/area').then((response) => {
        this.listArea = response.data.data;
      });
    },
    fetchRegion: async function () {
      this.listRegion = [];
      ApiService.setHeader();
      ApiService.get('/region').then((response) => {
        this.listRegion = response.data.data;
      });
    },
    onChangeListStore() {
      this.options[0].data = [];
      this.listStoreId.map((element) => {
        this.options[0].data.push(element);
      });
      let tmp = [{ data: this.options[0].data }];
      this.filteredOptions = tmp;
    },
  },
  validations: {
    fullName: {
      required,
      minLength: minLength(3),
    },
    selectedStore: {
      required,
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
